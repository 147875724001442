import css from './LangSelector.module.scss';

interface IProps {
  lang: 'ru' | 'en';
}

export default function LangSelector({ lang }: IProps) {
  const url = lang === 'ru' ? '/' : '/en';

  return (
    <div className={css.block}>
      <a href={url} className={css.link}>
        {lang}
      </a>
    </div>
  );
}
