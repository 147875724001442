import Image from 'next/image';

import type { SpeakerType } from 'graphql/types';

import config from 'json/config.json';

import css from './SpeakersItem.module.scss';

type TProps = SpeakerType;

export default function SpeakersItem(props: TProps) {
  const { firstName, lastName, title, photoUrl } = props;

  return (
    <div className={css.speakersItem}>
      <div className={css.speakersItemAvatar}>
        <Image
          src={photoUrl || '/speakers/speaker-empty.png'}
          layout="fill"
          alt={`${firstName} ${lastName}`}
          quality="75"
          blurDataURL="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mN8VA8AAkkBY8DEq9wAAAAASUVORK5CYII="
          placeholder="blur"
          unoptimized={config.disableImgOptim}
        />
      </div>
      <div className={css.speakersItemContent}>
        <b>
          {firstName} {lastName}
        </b>
        <p>{title}</p>
      </div>
    </div>
  );
}
