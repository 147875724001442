import Image from 'next/image';

import AnimOnScroll from 'components/common/service/AnimOnScroll';

import { UiButton } from 'components/ui';

import css from './Survey.module.scss';

export default function Survey() {
  return (
    <section id="survey">
      <AnimOnScroll delay={1}>
        <div className={css.block}>
          <div className={css.title}>
            Пройдите опрос, поделитесь своим мнением о знаниях, которые вы бы
            хотели получать!
          </div>
          <div className={css.text}>
            Среди всех, прошедших опрос,{' '}
            <span>разыграется 5 путешествий мечты</span> по России - до 2х
            недель на Алтае, Байкале и других красочных местах.
          </div>
          <div className={css.button}>
            <UiButton
              bgColor="#426FFF"
              color="#ffffff"
              size="medium"
              fluid
              href="https://sst.gl/znanie1"
              target="_blank"
            >
              Пройти опрос
            </UiButton>
          </div>
          <div className={css.icon}>
            <Image
              src="/marathon21/present.svg"
              width={35}
              height={35}
              alt=""
            />
          </div>
        </div>
      </AnimOnScroll>
    </section>
  );
}
