import Image from 'next/image';

import AnimOnScroll from 'components/common/service/AnimOnScroll';

import settings from 'json/marathon21.settings.json';
import config from 'json/config.json';

import css from './Map.module.scss';

interface IProps {
  locale?: string;
}

export default function Map({ locale = 'ru' }: IProps) {
  return (
    <section id="map">
      <AnimOnScroll delay={1}>
        <div className={css.block}>
          <Image
            src={settings.map[`source_${locale}`]}
            alt={settings.map[`label_${locale}`]}
            layout="fill"
            quality="100"
            objectFit="contain"
            loading="eager"
            unoptimized={config.disableImgOptim}
          />
        </div>
      </AnimOnScroll>
    </section>
  );
}
