import Image from 'next/image';
import Link from 'next/link';

import { UiButton } from 'components/ui';

import css from './Preview.module.scss';

const data = [
  // {
  //   id: 1,
  //   name: 'Премия Знания',
  //   url: 'http://premiya.znanierussia.ru',
  //   img: '/marathon21/preview1.png',
  // },
  {
    id: 1,
    name: 'Лига Лекторов',
    url: 'http://ligalektorov.znanierussia.ru/',
    img: '/marathon21/preview2.png',
  },
  {
    id: 2,
    name: 'Лига Знаний',
    url: 'http://ligaznaniy.znanierussia.ru/',
    img: '/marathon21/preview3.png',
  },
];

export default function Preview() {
  return (
    <section id="preview">
      <div className={css.block}>
        <h2 className={css.header}>Анонсы мероприятий</h2>
        <div className={css.content}>
          {data.map((item) => (
            <div key={item.id} className={css.item}>
              <div className={css.img}>
                <Image src={item.img} layout="fill" alt="" />
              </div>
              <div className={css.name}>{item.name}</div>
              <div className={css.action}>
                <Link href={item.url}>
                  <a target="_blank">
                    <UiButton variant="outline" size="small">
                      Подробнее
                    </UiButton>
                  </a>
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
