import { useState, useMemo, useEffect } from 'react';
import dayjs from 'dayjs';
import cn from 'classnames';

import ScheduleItem from '../ScheduleItem';

import { SpeakerType, EventDayType } from 'graphql/types';

import css from './Schedule.module.scss';

interface IProps {
  schedule: EventDayType[];
  speakers: SpeakerType[];
}

export default function Schedule({ schedule, speakers }: IProps) {
  const [activeDate, setActiveDate] = useState('');

  const dates = schedule.map((item) => {
    const day = dayjs(item.date).format('D');
    const monthName = dayjs(item.date).format('DD MMMM').split(' ')[1];

    return {
      label: `${day} ${monthName}`,
      value: item.date,
    };
  });

  const currentScheduleDay = useMemo(
    () => schedule.find((item) => item.date === activeDate),
    [schedule, activeDate]
  );
  const speakersList = (ids: string[] = []) =>
    ids.map((speakerId) =>
      speakers.find((speaker) => speaker.id === speakerId)
    );

  const handleDateClick = (value: string) => {
    setActiveDate(value);
    sessionStorage.setItem('schedule_date', value);
  };

  useEffect(() => {
    setActiveDate(
      sessionStorage.getItem('schedule_date')
        ? sessionStorage.getItem('schedule_date')
        : schedule[2].date
    );
  }, [schedule]);

  return (
    <div className={css.block}>
      <ul className={css.datesList}>
        {dates.map((d, i) => (
          <li
            className={cn(
              css.dateItem,
              activeDate === d.value && css.dateItemActive
            )}
            key={i}
            onClick={() => {
              handleDateClick(d.value);
            }}
          >
            {d.label}
          </li>
        ))}
      </ul>
      <div className={css.gridWrap}>
        {currentScheduleDay && (
          <div key={currentScheduleDay.date} className={css.grid}>
            {currentScheduleDay.eventItems.map((item) => (
              <ScheduleItem
                key={`${item.id}_${item.startTime}_${item.finishTime}`}
                speakers={speakersList(item.speakerIds)}
                {...item}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
