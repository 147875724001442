import { useState } from 'react';

import AnimOnScroll from 'components/common/service/AnimOnScroll';

import css from './AboutWithCircles.module.scss';

type TThesis = {
  label: string;
  value: number;
};

interface IProps {
  title: string;
  description: string | string[];
  thesises: TThesis[];
}

export default function AboutWithCircles(props: IProps) {
  const { title, description, thesises } = props;

  const [desc, setDesc] = useState<string | string[]>(
    typeof description === 'string' ? description : description[0]
  );

  const onClickMore = () => {
    if (typeof desc === 'string') {
      setDesc(description);
    } else {
      setDesc(description[0]);
    }
  };

  return (
    <section id="about">
      <AnimOnScroll delay={1}>
        <div className={css.container}>
          <div className={css.about}>
            <h2>{title}</h2>
            {typeof desc === 'string' ? (
              <p>
                {desc}{' '}
                {typeof description !== 'string' && (
                  <button type="button" onClick={onClickMore}>
                    Еще...
                  </button>
                )}
              </p>
            ) : (
              desc.map((item, index) => {
                return (
                  <p key={item}>
                    {item}{' '}
                    {index === desc.length - 1 && (
                      <button type="button" onClick={onClickMore}>
                        Скрыть
                      </button>
                    )}
                  </p>
                );
              })
            )}
          </div>
          <ul className={css.stats}>
            {thesises.map((t, index) => (
              <li key={`about_thesis_${index}`}>
                <strong>{t.value}</strong>
                {t.label}
              </li>
            ))}
          </ul>
        </div>
      </AnimOnScroll>
    </section>
  );
}
