import { useState } from 'react';
import Image from 'next/image';
import cn from 'classnames';

import AnimOnScroll from 'components/common/service/AnimOnScroll';

import events from './events.data.json';
import config from 'json/config.json';

import css from './Rosmolodej.module.scss';

export default function Rosmolodej() {
  const [activeItem, setActiveItem] = useState(null);

  return (
    <section id="rosmolodej" className={css.block}>
      <AnimOnScroll delay={1}>
        <div className={css.container}>
          <div className={css.row}>
            <h3 className={css.subtitle}>
              В рамках Марафона Новое Знание Росмолодежь проведет серию
              мероприятий ко Дню Знаний
            </h3>
            <div className={css.logo}>
              <Image
                src="/marathon21/rosmolodej-logo.png"
                layout="fill"
                alt="Лого Росмолодежь"
                unoptimized={config.disableImgOptim}
              />
            </div>
          </div>
          <p className={css.note}>
            С 1 по 4 сентября по всей стране пройдет большой марафон
            #ВпередКЗнаниям – серия мероприятий ко Дню знаний.
          </p>
          <ul className={css.eventsList}>
            {events.map((event, index) => (
              <li
                key={`rosmolodej_event_${index}`}
                className={css.eventItem}
                onClick={() => setActiveItem(index)}
              >
                <div className={css.eventRow}>
                  <div className={css.eventImg}>
                    <Image
                      src={`/marathon21/rosmolodej-events/activity${
                        index + 1
                      }.png`}
                      alt={`Изображение мероприятия - ${event.title}`}
                      layout="fill"
                      unoptimized={config.disableImgOptim}
                    />
                  </div>
                  <div className={css.eventInfo}>
                    <h5 className={css.eventInfoTitle}>{event.title}</h5>
                    <p
                      className={cn(
                        css.eventInfoText,
                        activeItem !== index && css.eventInfoTextCollapsed
                      )}
                    >
                      {event.description}
                    </p>
                  </div>
                  <div className={css.eventIcon}>
                    {activeItem === index ? '-' : '+'}
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </AnimOnScroll>
    </section>
  );
}
