import Image from 'next/image';
import cn from 'classnames';

import { UiText } from 'znanie-uikit';

import { SpeakerType } from 'graphql/types';

import config from 'json/config.json';

import css from './Speaker.module.scss';

interface IProps {
  speaker: SpeakerType;
  locale?: string;
  size?: 'medium' | 'large';
}

export default function Speaker({
  speaker,
  locale = 'ru',
  size = 'medium',
}: IProps) {
  const speakerName =
    locale === 'en'
      ? `${speaker.firstNameEn} ${speaker.lastNameEn}`
      : `${speaker.firstName} ${speaker.lastName}`;
  const post = locale === 'en' ? speaker.titleEn : speaker.title;

  return (
    <div className={cn(css.speaker, size && css[size])}>
      <div className={css.photo}>
        <Image
          src={speaker.photoUrl || '/speakers/speaker-empty.png'}
          alt={speakerName}
          layout="fill"
          quality="100"
          placeholder="blur"
          blurDataURL="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mN8Xw8AAmMBcHOnfc4AAAAASUVORK5CYII="
          unoptimized={config.disableImgOptim}
        />
      </div>
      <UiText align="center" size="small_plus" className={css.name}>
        {speakerName}
      </UiText>
      <UiText
        align="center"
        weight="medium"
        size="small_x"
        color="grey"
        className={css.post}
      >
        {post}
      </UiText>
    </div>
  );
}
