import Image from 'next/image';
import { useMemo, useState } from 'react';

import VictorinusWidget from 'components/marathon21/VictorinusWidget';

import AnimOnScroll from 'components/common/service/AnimOnScroll';

import { EventType } from 'graphql/types';

import settings from 'json/marathon21.settings.json';
import config from 'json/config.json';

import css from 'components/common/event/BroadcastPlayerNew/BroadcastPlayerNew.module.scss';

interface IProps {
  events: EventType[];
}

type CurrentBroadcastType = {
  id: number;
  slug: string;
  title: string;
  url: string;
  victorinusUrl: string;
};

const quizItem: CurrentBroadcastType = {
  id: 99,
  slug: 'marathon21_quiz',
  title: 'Финал квиз-марафона',
  url: 'https://vk.com/video_ext.php?oid=-206574855&id=456239018&hash=6eff589391568baa&autoplay=1',
  victorinusUrl: '',
};

export default function BroadcastPlayerNew({ events = [] }: IProps) {
  const broadcasts = useMemo(() => {
    return events.reduce(
      (res, item) =>
        item.broadcasts?.find((i) => i.published)
          ? [
              ...res,
              {
                id: item.id,
                slug: item.slug,
                title: item.currentEventItem?.title,
                url: item.broadcasts?.find((i) => i.published).url,
                victorinusUrl: item.broadcasts?.find((i) => i.published)
                  .victorinusUrl,
              },
            ]
          : res,
      []
    );
  }, [events]);
  const [translation, setTranslation] = useState<CurrentBroadcastType | null>(
    broadcasts.length > 0
      ? broadcasts.find((i) => i.slug === 'marathon21_moscow')
      : null
  );

  const handleSelectStream = (opt: CurrentBroadcastType) => {
    const translationObj = broadcasts.find((t) => t.id === opt.id);

    setTranslation(translationObj);
  };

  const broadcastsList = useMemo(() => {
    return broadcasts.filter((t) => t.id !== translation?.id);
  }, [translation, broadcasts]);

  return (
    <section id="broadcast">
      <AnimOnScroll delay={2}>
        <div className={css.block}>
          <div className={css.playerContainer}>
            <div className={css.mainPlayer}>
              <div className={css.playerInner}>
                {translation && (
                  <iframe
                    className={css.player}
                    src={translation.url}
                    title={translation.title}
                    frameBorder="0"
                    allowFullScreen
                  />
                )}
              </div>
            </div>
            <div className={css.listContainer}>
              {broadcastsList.map((item) => (
                <div
                  className={css.miniPlayer}
                  key={item.id}
                  onClick={() => handleSelectStream(item)}
                >
                  <div className={css.playerInner}>
                    <Image
                      src={settings.broadcasts[item.slug]}
                      layout="fill"
                      alt=""
                      unoptimized={config.disableImgOptim}
                    />
                  </div>
                  <div className={css.desc}>
                    <div className={css.theme}>
                      {settings.topics[item?.slug]}
                    </div>
                    <div className={css.name}>
                      {settings.cities.find((c) => c.value === item.slug).label}
                    </div>
                    {/* <div className={css.name}>{item?.title}</div> */}
                  </div>
                </div>
              ))}
              <div
                className={css.miniPlayer}
                onClick={() => setTranslation(quizItem)}
              >
                <div className={css.playerInner}>
                  <Image
                    src="/marathon21/broadcasts/quiz.jpeg"
                    layout="fill"
                    alt=""
                    unoptimized={config.disableImgOptim}
                  />
                </div>
                <div className={css.desc}>
                  <div className={css.theme}>Квиз-марафон</div>
                  <div className={css.name}>Москва</div>
                </div>
              </div>
            </div>
          </div>
          <div className={css.descMain}>
            <div className={css.theme}>
              {translation?.slug === 'marathon21_quiz'
                ? 'Финал Всероссийского Квиз-марафона'
                : settings.topics[translation?.slug]}
            </div>
            {/* {translation?.title && (
              <>
                <div className={css.text}>Тема текущего выступления:</div>
                <div className={css.name}>{translation?.title}</div>
              </>
            )} */}
            <div className={css.name}>
              {translation?.slug === 'marathon21_quiz'
                ? 'Москва'
                : settings.cities.find((c) => c.value === translation?.slug)
                    ?.label}
            </div>
          </div>
          {translation && translation?.victorinusUrl && (
            <VictorinusWidget url={translation.victorinusUrl} />
          )}
        </div>
      </AnimOnScroll>
    </section>
  );
}

BroadcastPlayerNew.defaultProps = {
  playerBg: '',
  playerBorderRadius: 0,
};
