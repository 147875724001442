import { useEffect } from 'react';
import { useQuery, useLazyQuery } from '@apollo/client';

import initializeApollo, { addApolloState } from 'apollo-client';

import Head from 'components/common/markup/Head';
import Navbar from 'components/common/markup/Navbar';
import Content from 'components/common/markup/Content';
import Footer from 'components/common/markup/Footer';

import BroadcastPlayer from 'components/common/event/BroadcastPlayerNew';
import Partners from 'components/common/event/Partners';

import MenuLogo from 'components/marathon21/MenuLogo';
import LangSelector from 'components/marathon21/LangSelector';
import MainSlider from 'components/marathon21/MainSlider';
import AboutWithCircles from 'components/common/event/AboutWithCircles';
import SpeakersSlider from 'components/marathon21/SpeakersSlider';
import Map from 'components/marathon21/Map';
import Results from 'components/marathon21/Results';
import Accreditation from 'components/marathon21/Accreditation';
import ScheduleWrapper from 'components/marathon21/ScheduleWrapper';
import Videos from 'components/marathon21/Videos';
import Survey from 'components/marathon21/Survey';
import Rosmolodej from 'components/marathon21/Rosmolodej';

import settings from 'json/marathon21.settings.json';

import QUERY_EVENT from 'graphql/event.graphql';
import QUERY_BROADCASTS from 'graphql/broadcast.graphql';

import {
  GetEventQuery,
  GetEventQueryVariables,
  EventType,
  QueryEventsBySlugsArgs,
} from 'graphql/types';
import Prizes from 'components/marathon21/Prizes';
import Preview from 'components/marathon21/Preview';
import Activity from 'components/marathon21/Activity';

function Marathon() {
  const { data } = useQuery<GetEventQuery>(QUERY_EVENT, {
    variables: {
      slug: settings.slug,
    } as GetEventQueryVariables,
  });
  const [fetchEvent, { data: cityData, loading: cityLoading }] =
    useLazyQuery<GetEventQuery>(QUERY_EVENT, {
      variables: {
        slug: settings.cities[0].value,
      } as GetEventQueryVariables,
    });
  const { data: eventsByBroadcasts } = useQuery(QUERY_BROADCASTS, {
    variables: {
      slugs: settings.cities.map((i) => i.value),
    } as QueryEventsBySlugsArgs,
  });
  const event = data?.eventBySlug as EventType;
  const cityEvent = cityData?.eventBySlug as EventType;
  const metaData = {
    title: event?.name || '',
    description: event?.description || '',
  };

  const filteredBroadcasts = eventsByBroadcasts?.eventsBySlugs;

  const handleCityChange = (city: string) => {
    fetchEvent({ variables: { slug: city } });
  };

  useEffect(() => {
    fetchEvent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Head metaData={metaData} shareImage="/marathon21/share.jpeg" />
      <Navbar
        menuItems={settings.menu}
        desktopLogo
        customContent={() => (
          <>
            <MenuLogo />
            <LangSelector lang="en" />
          </>
        )}
      />
      <Content>
        {filteredBroadcasts && filteredBroadcasts.length > 0 && (
          <BroadcastPlayer events={filteredBroadcasts} />
        )}
        <Prizes />
        {/* <MainSlider />*/}
        <ScheduleWrapper
          schedule={cityEvent?.eventDays || []}
          speakers={cityEvent?.speakers || []}
          cities={settings.cities}
          topics={settings.topics}
          onCityClick={handleCityChange}
          scheduleLoading={cityLoading}
        />
        <AboutWithCircles
          title={settings.about.title_ru}
          description={event?.description || ''}
          thesises={settings.about.thesises_ru}
        />
        {event?.headliners.length && (
          <SpeakersSlider speakers={event.headliners} />
        )}
        <Survey />
        <Map />
        <Results />
        {event?.content.length && <Videos data={event.content} />}
        <Preview />
        <Activity />
        <Rosmolodej />
        <Accreditation />
        {event?.partners.length && <Partners partners={event.partners} />}
      </Content>
      <Footer />
    </>
  );
}

export async function getServerSideProps() {
  const apolloClient = initializeApollo();

  try {
    await apolloClient.query({
      query: QUERY_EVENT,
      variables: { slug: settings.slug },
    });

    await apolloClient.query({
      query: QUERY_BROADCASTS,
      variables: {
        slugs: settings.cities.map((i) => i.value),
      },
    });
  } catch (err) {
    console.log(err);
  }

  try {
    await apolloClient.query({
      query: QUERY_EVENT,
      variables: { slug: settings.cities[0].value },
    });
  } catch (err) {
    console.log(err);
  }

  return addApolloState(apolloClient, {
    props: {},
  });
}

export default Marathon;
