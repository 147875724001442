import AnimOnScroll from 'components/common/service/AnimOnScroll';

import settings from 'json/marathon21.settings.json';

import css from './Results.module.scss';

interface IProps {
  locale?: string;
}

export default function Results({ locale = 'ru' }: IProps) {
  return (
    <section id="results">
      <AnimOnScroll delay={1}>
        <div className={css.block}>
          <h2 className={css.title}>{settings.results[`title_${locale}`]}</h2>
          <div className={css.items}>
            {settings.results.list.map((r, i) => (
              <div className={css.item} key={i}>
                <div className={css.figure}>
                  <div className={css.value}>{r.value}</div>
                  <div className={css.label}>{r[`label_${locale}`]}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </AnimOnScroll>
    </section>
  );
}
