import Link from 'next/link';

import AnimOnScroll from 'components/common/service/AnimOnScroll';
import SpeakersSlider from 'components/common/event/SpeakersSlider';

import { SpeakerType } from 'graphql/types';

import css from './SpeakersSlider.module.scss';

interface IProps {
  title?: string;
  locale?: string;
  speakers: SpeakerType[];
}

export default function CustomSpeakersSlider({
  title = 'Спикеры',
  locale = 'ru',
  speakers,
}: IProps) {
  const speakersUrl = '/speakers';

  return (
    <section id="speakers">
      <AnimOnScroll delay={1}>
        <div className={css.block}>
          <div className={css.header}>
            <h2 className={css.title}>{title}</h2>
            {locale === 'ru' && (
              <div className={css.link}>
                <Link href={speakersUrl}>
                  <a>Смотреть всех {'->'}</a>
                </Link>
              </div>
            )}
          </div>
          <SpeakersSlider
            locale={locale}
            speakersUrl={speakersUrl}
            speakers={speakers}
            showMore={locale === 'ru'}
          />
        </div>
      </AnimOnScroll>
    </section>
  );
}
