import React from 'react';
import cn from 'classnames';
import Link from 'next/link';
import AnimOnScroll from 'components/common/service/AnimOnScroll';

import { PartnerType } from 'graphql/types';

import css from './Partners.module.scss';

interface IProps {
  title?: string;
  partners: PartnerType[];
}

interface ILogo {
  partner: PartnerType;
}

const Logo = ({ partner }: ILogo) => {
  return (
    <div
      className={cn(css.img, css[partner.slug])}
      style={{ backgroundImage: `url(${partner.logoUrl})` }}
    />
  );
};

export default function Partners({ title = 'Партнеры', partners }: IProps) {
  return (
    <section id="partners">
      <AnimOnScroll delay={1}>
        <div className={css.block}>
          <h2 className={css.title}>{title}</h2>
          <div className={css.list}>
            {partners.map((p) => (
              <React.Fragment key={p.id}>
                {p.url ? (
                  <Link href={p.url}>
                    <a target="_blank">
                      <Logo partner={p} />
                    </a>
                  </Link>
                ) : (
                  <Logo partner={p} />
                )}
              </React.Fragment>
            ))}
          </div>
        </div>
      </AnimOnScroll>
    </section>
  );
}
