import { UiButton } from 'components/ui';

import AnimOnScroll from 'components/common/service/AnimOnScroll';

import css from './Accreditation.module.scss';

export default function Accreditation() {
  return (
    <section id="accreditation">
      <AnimOnScroll delay={1}>
        <div className={css.block}>
          <h2 className={css.title}>Аккредитация СМИ</h2>
          <div className={css.text}>
            <p>
              Открыта аккредитация на площадки II федерального просветительского
              марафона {'"Новое знание"'} в Москве.
            </p>
            <p>
              Для получения аккредитации просим представителей СМИ заполнить
              форму до 14:00 мск 31.08.2021.
            </p>{' '}
            <p>
              По вопросам, связанным с аккредитацией, просьба обращаться в
              пресс-центр по электронной почте{' '}
              <a href="mailto:pr@znanierussia.ru">pr@znanierussia.ru</a>
            </p>
          </div>
          <UiButton
            bgColor="#426FFF"
            color="#ffffff"
            size="medium"
            href="https://forms.gle/t8bVGjkCky3LWsng7"
            target="_blank"
          >
            Получить аккредитацию
          </UiButton>
        </div>
      </AnimOnScroll>
    </section>
  );
}
