import { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import cn from 'classnames';
import Image from 'next/image';

import Schedule from './Schedule';

import AnimOnScroll from 'components/common/service/AnimOnScroll';

import { SpeakerType, EventDayType } from 'graphql/types';

import css from './ScheduleWrapper.module.scss';

type TCityItem = {
  label: string;
  value: string;
};

interface IProps {
  cities?: TCityItem[];
  topics?: any;
  schedule: EventDayType[];
  speakers: SpeakerType[];
  onCityClick(city: string): void;
  scheduleLoading?: boolean;
}

export default function ScheduleWrapper({
  cities,
  topics,
  schedule,
  speakers,
  onCityClick,
  scheduleLoading,
}: IProps) {
  const { asPath } = useRouter();
  const cityValue = asPath.includes('city')
    ? asPath.split('?')[1].split('=')[1]
    : cities[0].value;
  const [activeCity, setActiveCity] = useState(cities[0].value);

  const handleCityClick = (city: string) => {
    onCityClick(city);
    setActiveCity(city);
  };

  useEffect(() => {
    if (asPath.includes('city')) {
      setTimeout(() => {
        handleCityClick(cityValue);
        document.querySelector('#schedule').scrollIntoView();
      }, 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section id="schedule">
      <AnimOnScroll delay={1}>
        <div className={css.block}>
          <h2 className={css.title}>Программа форума</h2>
          <h3 className={css.subtitle}>По местному времени</h3>
          <div className={css.citiesListWrap}>
            <ul className={css.citiesList}>
              {cities.map((c, i) => (
                <li
                  className={cn(
                    css.cityItem,
                    activeCity === c.value && css.cityItemActive
                  )}
                  key={`schedule_city_${i}`}
                >
                  <a
                    href={`#schedule?city=${c.value}`}
                    onClick={() => {
                      handleCityClick(c.value);
                    }}
                  >
                    <div className={css.cityItemTopic}>{topics[c.value]}</div>
                    <div className={css.cityItemLabel}>{c.label}</div>
                  </a>
                </li>
              ))}
            </ul>
          </div>
          {scheduleLoading ? (
            <>идет загрузка....</>
          ) : !scheduleLoading && schedule.length === 0 ? (
            <div className={css.empty}>
              <Image
                src="/marathon21/empty-list.png"
                width={114}
                height={153}
                alt=""
              />
              <div className={css.emptyTitle}>Программа уточняется</div>
            </div>
          ) : (
            !scheduleLoading &&
            schedule.length > 0 &&
            speakers.length > 0 && (
              <Schedule schedule={schedule} speakers={speakers} />
            )
          )}
        </div>
      </AnimOnScroll>
    </section>
  );
}
