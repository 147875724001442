import Image from 'next/image';

import config from 'json/config.json';

import css from './Activity.module.scss';

const data = [
  {
    id: 1,
    title: 'Выступления в школах',
    date: '1-3 сентября',
    text: '1500+ выдающихся спикеров от партнерских проектов и организаций проведут уроки в своих родных школах и поделятся знанием по самым интересным и актуальным темам со школьниками',
    img: '/marathon21/activity1.png',
  },
  {
    id: 2,
    title: 'Дни открытых дверей',
    date: '1-3 сентября',
    text: 'В рамках Марафона крупнейшие партнеры организации и проекты распахнут свои двери для школьников, студентов и молодых специалистов и проведут экскурсии, профориентационные лекции и мастер-классы',
    img: '/marathon21/activity2.png',
  },
];
export default function Activity() {
  return (
    <section id="activity">
      <div className={css.block}>
        <h2 className={css.header}>Мероприятия в рамках Марафона</h2>
        <div className={css.content}>
          {data.map((i) => (
            <div key={i.id} className={css.item}>
              <div className={css.img}>
                <Image
                  src={i.img}
                  layout="fill"
                  objectFit="contain"
                  alt=""
                  unoptimized={config.disableImgOptim}
                />
              </div>
              <div className={css.desc}>
                <div className={css.title}>{i.title}</div>
                <div className={css.date}>{i.date}</div>
                <div className={css.text}>{i.text}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
