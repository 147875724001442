import Link from 'next/link';
import cn from 'classnames';

import { UiSlider } from 'components/ui';

import type { SpeakerType } from 'graphql/types';

import css from './SpeakersSlider.module.scss';

import Speaker from '../Speaker';

interface IProps {
  speakers: SpeakerType[];
  speakersUrl: string;
  showMore?: boolean;
  locale?: string;
  cardSize?: 'medium' | 'large';
}

const settings = {
  slidesToShow: 4,
  slidesToScroll: 1,
  arrows: true,
  infinite: true,
  responsive: [
    {
      breakpoint: 1000,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 768,
      settings: {
        centerMode: true,
        infinite: true,
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 481,
      settings: {
        centerMode: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
  nextArrow: <UiSlider.NextArrow />,
  prevArrow: <UiSlider.PrevArrow />,
};

export default function SpeakersSlider({
  speakers,
  speakersUrl,
  showMore,
  locale,
  cardSize,
}: IProps) {
  return (
    <div className={cn(css.speakers, showMore && css.withBtn)}>
      <UiSlider {...settings} className={css.slider}>
        {speakers.map((speaker) => (
          <Speaker
            key={speaker.id}
            size={cardSize}
            speaker={speaker}
            locale={locale}
          />
        ))}
      </UiSlider>
      {showMore && (
        <div className={css.link}>
          <Link href={speakersUrl}>
            <a>Показать всех</a>
          </Link>
        </div>
      )}
    </div>
  );
}

SpeakersSlider.defaultProps = {
  showMore: false,
  locale: 'ru',
  cardSize: 'medium',
};
