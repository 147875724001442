import Image from 'next/image';

import config from 'json/config.json';

import css from './MenuLogo.module.scss';

export default function MenuLogo() {
  return (
    <div className={css.block}>
      <Image
        src="/marathon21/science_tech_year.png"
        width={72}
        height={72}
        quality="100"
        layout="fixed"
        alt="лого 2021 год науки"
        unoptimized={config.disableImgOptim}
      />
    </div>
  );
}
