import { useState, useEffect } from 'react';

import css from './VictorinusWidget.module.scss';

interface IProps {
  url: string;
}

export default function VictorinusWidget({ url }: IProps) {
  const [widgetUrl, setWidgetUrl] = useState('');

  useEffect(() => {
    // хак, чтобы содержимое iframe изменилось, иначе меняется только ссылка
    setWidgetUrl('');
    setTimeout(() => {
      setWidgetUrl(url);
    }, 0);
  }, [url]);

  return (
    <div className={css.block}>
      {widgetUrl && (
        <iframe className={css.player} src={widgetUrl} frameBorder="0" />
      )}
    </div>
  );
}
