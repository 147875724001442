import css from './Prizes.module.scss';
import AnimOnScroll from 'components/common/service/AnimOnScroll';
import Link from 'next/link';

export default function Prizes() {
  return (
    <section id="prizes">
      <AnimOnScroll delay={1}>
        <div className={css.block}>
          <div className={css.header}>
            <h2 className={css.title}>Призы за активное участие</h2>
            <div className={css.link}>
              <Link href="https://vk.com/dd_app?ref=catalog_recent">
                <a target="_blank"> Все призы {'->'}</a>
              </Link>
            </div>
          </div>
          <div className={css.text}>
            <div>
              Участвуйте в интерактивах на стриме прямо на сайте или в ВК и
              получайте за это призы. Сами интерактивы будут появляться на
              трансляции и анонсироваться ведущими.
            </div>
            <ul>
              <li>Голосуйте в дебатах</li>
              <li>Задавайте вопросы спикерам</li>
              <li>Участвуйте в опросах</li>
            </ul>
            <div className={css.subtext}>
              <div className={css.subheader}>
                Выиграйте одно из 13 путешествий
              </div>
              <div>по самым красивым местам России!</div>
            </div>
          </div>
        </div>
      </AnimOnScroll>
    </section>
  );
}
