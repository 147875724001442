import { useState } from 'react';

import AnimOnScroll from 'components/common/service/AnimOnScroll';

import { UiSlider, UiModal } from 'components/ui';

import { ContentType } from 'graphql/types';

import css from './Videos.module.scss';

interface IProps {
  data: ContentType[];
}

export default function Videos({ data }: IProps) {
  const [selectedVideo, setSelectedVideo] = useState<ContentType | null>(null);

  const settings = {
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: true,
    infinite: true,
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          centerMode: true,
          arrows: false,
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    nextArrow: <UiSlider.NextArrow />,
    prevArrow: <UiSlider.PrevArrow />,
  };

  const handleVideoClick = (item: ContentType) => {
    setSelectedVideo(item);
  };

  return (
    <>
      <section id="videos" className={css.block}>
        <AnimOnScroll delay={1}>
          <div className={css.head}>
            <h2 className={css.title}>Прошедшие выступления</h2>
            <div className={css.link}>
              <a
                href="https://vk.com/videos-135454514"
                target="_blank"
                rel="noreferrer"
              >
                Смотреть все {'->'}
              </a>
            </div>
          </div>
          <div className={css.videoSlider}>
            <UiSlider {...settings}>
              {data.map((item: ContentType, index: number) => (
                <div className={css.videoItem} key={`videoItem_${index}`}>
                  <div
                    className={css.videoPreview}
                    onClick={() => handleVideoClick(item)}
                    style={{ backgroundImage: `url(${item.coverUrl})` }}
                  />
                  <div className={css.videoTitle}>{item.title}</div>
                </div>
              ))}
            </UiSlider>
          </div>
        </AnimOnScroll>
      </section>
      <UiModal
        isShowing={!!selectedVideo}
        onClose={() => {
          setSelectedVideo(null);
        }}
      >
        <div className={css.videoPlayer}>
          <iframe src={selectedVideo?.videoUrl} />
        </div>
        <div className={css.videoTitle}>{selectedVideo?.title}</div>
      </UiModal>
    </>
  );
}
