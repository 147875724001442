import dayjs from 'dayjs';

import type { EventItemType } from 'graphql/types';

import SpeakersItem from '../SpeakersItem';

import css from './ScheduleItem.module.scss';

export default function ScheduleItem(props: EventItemType) {
  const { title, description, startTime, finishTime, speakers } = props;

  const getTimeFormat = (date: string) => dayjs(date).format('HH:mm');

  return (
    <div className={css.item}>
      <div className={css.itemTitle}>
        <div className={css.itemTitleText}>
          {getTimeFormat(startTime)} - {getTimeFormat(finishTime)}
        </div>
        <div className={css.itemTitleCalendar}></div>
      </div>
      <div className={css.content}>
        <div className={css.subTitle}>{title}</div>
        {description && <p>{description}</p>}
        <div className={css.speakersList}>
          {speakers.map((speaker) => (
            <SpeakersItem key={speaker.id} {...speaker} />
          ))}
        </div>
      </div>
    </div>
  );
}
