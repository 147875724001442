import { ReactNode, useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

import { fadeVars } from 'utils/animations';

interface IProps {
  delay?: number;
  children: ReactNode;
}

export default function AnimOnScroll({ delay, children }: IProps) {
  const controls = useAnimation();
  const { ref, inView } = useInView({
    threshold: 0.1,
  });

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  return (
    <motion.div
      ref={ref}
      initial="hidden"
      animate={controls}
      custom={delay}
      variants={fadeVars}
    >
      {children}
    </motion.div>
  );
}

AnimOnScroll.defaultProps = {
  delay: 0,
};
